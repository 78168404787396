import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ReactComponent as User } from '../../../assets/images/scouts/user.svg'
import { ReactComponent as Settings } from '../../../assets/images/scouts/setting.svg'
import { ReactComponent as LogOut } from '../../../assets/images/scouts/logout.svg'
import './ProfileToggle.scss'
import * as TrackingActions from '../../../redux/trackingFlowControl/TrackingFlowControlActions'

const ProfileToggle = props => {
  const disptach = useDispatch()
  const { startupUser } = useSelector(state => state.auth)
  const { profile_data } = useSelector(state => state.trackingFlowControlReducer)
  const dispatch = useDispatch()
  const [activeProfile, setActiveProfile] = useState(false)
  const [activeCompanyInfo, setActiveCompanyInfo] = useState(false)

  // Fetching profile data on component mount
  // useEffect(() => {
  //   dispatch(TrackingActions.fetchProfileData())
  // }, [])

  // useEffect(() => {}, [profile_data])

  // Handles the click event for toggling the profile section visibility.
  const handleProfileClick = () => {
    setActiveProfile(!activeProfile)
  }

  // Handles the click event for toggling the company information section visibility and navigates to the company profile page.
  const handleCompanyInfoClick = () => {
    setActiveCompanyInfo(!activeCompanyInfo)
    props.history.push('/tracking/profile/company')
  }

  // Handles the click event for toggling the company information section visibility and navigates to the email notification settings page.
  const handleProfileSettingClick = () => {
    setActiveCompanyInfo(!activeCompanyInfo)
    props.history.push('/tracking/setting/EmailNotification')
  }

  // Handles the click event for logging out the user by clearing local storage and redirecting to the home page.
  const handleLogout = () => {
    localStorage.clear()
    window.location.href = '/'
  }

  return (
    <div className="custom-dropdown">
      <Dropdown isOpen={activeProfile} toggle={handleProfileClick}>
        <DropdownToggle>
          <span
            className="align-items-center justify-content-center notification-dummy"
            onClick={handleProfileClick}
          >
            {profile_data?.data?.co_founders[0]?.profile_image ? (
              <img src={profile_data?.data?.co_founders[0]?.profile_image} className="notification-dummy" />
            ) : (
              profile_data?.data?.first_name?.charAt(0).toUpperCase()
            )}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleCompanyInfoClick}>
            <User className="mr-3" />
            Profile
          </DropdownItem>
          <DropdownItem onClick={handleProfileSettingClick}>
            <Settings className="mr-3" />
            Settings
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>
            <LogOut className="mr-3" />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default withRouter(ProfileToggle)
