import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Row, Col, Spin, Avatar, Popover } from 'antd'
import moment from 'moment'

import './Header.scss'
import ProfileToggle from '../../../pages/TrackingPortal/TrackingProfile/ProfileToggle'
import Logo from '../../../assets/images/LogoExitFundMobile.svg'
import Bell from '../../../assets/images/bell.svg'
import Avtar from '../../../assets/images/tracking/avtar.png'
import NoNotification from '../../../assets/images/no-notification.png'
import * as StratupApprovedActions from '../../../redux/startupApprovedFlowControl/StratupApprovedFlowControlActions'
import * as TrackingActions from '../../../redux/trackingFlowControl/TrackingFlowControlActions'

// Header for mobile view
const HeaderTrackingMobile = props => {
  const currentPath = props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]
  const { loading, unread_notification_count, unread_notifications } = useSelector(
    state => state.StratupApprovedFlowControlReducers,
  )
  const dispatch = useDispatch()
  const [unread, setUnread] = useState(0)

  // Fetch the unread notification count on component mount.
  useEffect(() => {
    dispatch(TrackingActions.fetchProfileData())
    dispatch(StratupApprovedActions.fetchUnreadNotificationCount())
  }, [])

  // Updates the component state with the latest unread notification count.
  useEffect(() => {
    setUnread(unread_notification_count)
  }, [unread_notification_count])

  // Function to navigate to the monthlyupdate page when toggling the logo.
  const toggleLogoToHome = () => {
    props.history.push('/tracking/monthly-update')
  }

  /**
   * Handles the visibility change of the notification dropdown.
   * Resets the unread count to 0 when the dropdown is not visible.
   * @param {boolean} visibility - The visibility state of the notification dropdown.
   */
  const VisibleChangeHandler = visiblity => {
    if (!visiblity) {
      setUnread(0)
    }
  }

  /**
   * Handles the click event on the notification icon.
   * Fetches the unread notifications on click.
   */
  const onNotificationClick = () => {
    dispatch(StratupApprovedActions.fetchUnreadNotifications())
  }

  /**
   * Handles the click event to clear all notifications.
   * Clears all notifications when clicked.
   */
  const onClearNotification = () => {
    dispatch(StratupApprovedActions.clearAllNotification())
  }

  /**
   * Component that displays notifications.
   * @component
   * @returns {JSX.Element} Notifications component.
   */
  const notifications = (
    <div className="notifications-content-wrapper">
      {loading ? (
        <Spin />
      ) : (
        <div className="tracking-section">
          <div className="pop-header">
            <h4>Notification</h4>
            <span className="clear-all" onClick={() => onClearNotification()}>
              Clear all
            </span>
          </div>
          <div className="pop-content">
            {unread_notifications?.data?.map(item => (
              <div className="line-hr">
                <Row gutter={12}>
                  <Col span={4}>
                    <div className="alignments">
                      <Avatar src={item?.profile_pic}>{item?.first_name?.[0]?.toUpperCase() || 'A'}</Avatar>
                    </div>
                  </Col>
                  <Col span={20}>
                    <div className="alignments">
                      <span className="notification-msg">{item?.activity}</span>
                      <div className="timing">{moment(item?.timestamp).utc(true).fromNow()}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <div className="pop-footer">
            <span
              onClick={() => {
                setUnread(0)
                props.history.push('/tracking/notifications')
              }}
            >
              View All
            </span>
          </div>
        </div>
      )}
    </div>
  )

  // Content when there are no notifications.
  const emptyNotificationContent = (
    <div className="empty-tracking-notification-content-wrap">
      <div className="pop-header">
        <h4>Notification</h4>
        <span className="clear-all" onClick={() => props.history.push('/tracking/notifications')}>
          View all
        </span>
      </div>
      <div className="pop-content">
        <img src={NoNotification} alt="" />
        <span className="no-notification-text">No new notifications</span>
      </div>
    </div>
  )

  return (
    <div className="header-mobile m-0 w-100 flex justify-content-between sticky-top">
      <div className="logo" id="home-logo">
        <img src={Logo} onClick={toggleLogoToHome} alt="Logo - Exitfund" />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Popover
          arrowContent={null}
          content={unread_notifications?.data?.length > 0 ? notifications : emptyNotificationContent}
          placement="bottomRight"
          trigger="click"
          onVisibleChange={visiblity => VisibleChangeHandler(visiblity)}
        >
          <Badge count={unread} onClick={() => onNotificationClick()} className="mr-3">
            <img src={Bell} alt="" />
          </Badge>
        </Popover>
        <ProfileToggle />
      </div>
    </div>
  )
}

export default withRouter(HeaderTrackingMobile)
